<template>
    <Menu active="realisation" menu_home="false" />
    <div class="w-full flex-col">
        <TitleSection
            h2Title="Réalisations/"
            subTitle="projets()"
            titleContSize="w-61"
            txtContSize="w-39"
            txtTitle="Découvrez mes dernières réalisations, des projets diversifiés avec des problématiques différentes. Faites-vous une idée de ce que pourrait être votre site de demain. Visiter les sites pour découvrir tous les détails cachés."
            />
        <div class="w-full flex-col">
            <div class="w-full flex-row">
                <div class="w-10 b-b b-l b-r bg-red tab-w-15 tel-w-30" id="yearTagRealisation">
                    <p>en<span>2022</span></p>
                </div>
                <div class="w-90 b-b b-r tab-w-85 tel-w-70">
                    
                </div>
            </div>
            <RealisationCard v-for="(card, index) in dataRealisationsTwoTwo" :info_realisation="card" :key="index" />
            <div class="w-full flex-row">
                <div class="w-10 b-b b-l b-r bg-blue tab-w-15 tel-w-30" id="yearTagRealisation">
                    <p>en<span>2021</span></p>
                </div>
                <div class="w-90 b-b b-r tab-w-85 tel-w-70">
                    
                </div>
            </div>
            <RealisationCard v-for="(card, index) in dataRealisationsTwoOne" :info_realisation="card" :key="index" />
            <div class="w-full flex-row">
                <div class="w-10 b-b b-l b-r bg-green tab-w-15 tel-w-30" id="yearTagRealisation">
                    <p>en<span>2020</span></p>
                </div>
                <div class="w-90 b-b b-r tab-w-85 tel-w-70">
                    
                </div>
            </div>
            <RealisationCard v-for="(card, index) in dataRealisationsTwoZero" :info_realisation="card" :key="index" />
        </div>
        <Footer />
    </div>
</template>

<script>
//BDD
import database from '../databases/__realisations'

import { onMounted, ref } from 'vue'
// COMPONENTS
import Menu from "../components/_partials/Menu.vue";
import TitleSection from "../components/_partials/TitleSection.vue";
import RealisationCard from "../components/_partials/RealisationCard.vue";
import Footer from "../components/_partials/Footer.vue";

export default {
    components: {
        Menu,
        TitleSection,
        RealisationCard,
        Footer,
    },
    setup() {
        class Realisations {
            constructor (name, link, summary, image, date) {
                this.name = name
                this.link = link
                this.summary = summary
                this.image = image
                this.date = date
            }
        }

        let dataRealisationsTwoZero = ref([]) //2020
        let dataRealisationsTwoOne = ref([]) //2021
        let dataRealisationsTwoTwo = ref([]) //2022

        const makeDataRealisations = () => {

            for (const realisation of database) {
                const new_realisation = new Realisations(realisation.name, realisation.link, realisation.summary, realisation.image, realisation.date)
                switch (new Date(realisation.date).getFullYear()) {
                    case 2020:
                        dataRealisationsTwoZero.value.push(new_realisation)
                        break;
                    case 2021:
                        dataRealisationsTwoOne.value.push(new_realisation)
                        break;
                    case 2022:
                        dataRealisationsTwoTwo.value.push(new_realisation)
                        break;
                }
            }
        }

        onMounted(makeDataRealisations);
        
        //Return
        return {
            dataRealisationsTwoZero,
            dataRealisationsTwoOne,
            dataRealisationsTwoTwo,
        };
    },
    mounted() {  
        document.title = 'Réalisations → rejoignez l\'aventure';  
    },
    metaInfo() {
        return { 
            title: "🎨 Réalisations → rejoignez l'aventure avec votre projet",
            meta: [
                { name: 'description', content:  'Choisisser d\'être accompagner pour vos projets numérique. De la création de site web vitrine comme e-commerce, à la communication réseaux sociaux et l\'analyse de vos données.'},
                { property: 'og:title', content: "🎨 Réalisations → rejoignez l'aventure avec votre projet"},
                { property: 'og:site_name', content: 'Arnaud Laborde_'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'index,follow'},
                { property: 'og:description', content: 'Choisisser d\'être accompagner pour vos projets numérique. De la création de site web vitrine comme e-commerce, à la communication réseaux sociaux et l\'analyse de vos données.'},
                {property: 'og:url', content: 'https://arnaudlaborde.fr/realisations'},
                {property: 'og:image', content: '@/assets/meta-image/realisations.png' } 
            ]
        }
    }
}
</script>

<style>
.testSectionclass {
    margin: 5em 5% 2em;
}
.box {
    width: 90%;
    transform: translateX(8%);
    transition: all .3s ease-out;
    cursor: pointer;
    padding-bottom: 1.2rem;
    margin: 0 3% 2rem;
}
.box .left {
    display: block;
    width: 5px;
    height: 0;
    position: absolute;
    top: -3px;
    left: -17.5px;
    background: #131313;
    transition: all .5s cubic-bezier(0.000, 0.995, 0.990, 1.000);
    animation: borderLeftLoad 2s cubic-bezier(0.000, 0.995, 0.990, 1.000) 1s forwards;
}
@keyframes borderLeftLoad {
    from {
        height: 0;
    }
    to {
        height: calc( 100% + 0px );
    }
}

p {
    margin: 0;
}
.box .changeYear {
    margin-top: 30px;
}
.box .changeYear > .circle {
    width: 11px;
    height: 11px;
    background: #131313;
    transform: translateX(-187.5%);
    border-radius: 50%;
}
.changeYear > p {
    font-size: 18px;
    font-weight: 200;
    transform: translateX(-.2em);
}
.year {
    font-size: 24px;
    font-weight: 400;
    margin-left: 0.2em;
}
@media (min-width: 375px) {

}
@media (min-width: 425px) {

}
@media (min-width: 768px) {

}
@media (min-width: 1024px) {
    .box {
        width: 89%;
    }
    .box .left {
        width: 7px;
        top: -3px;
        left: -30px;
    }
    .box .changeYear > .circle {
        width: 23px;
        height: 23px;
        transform: translateX(-165%);
    }
}
@media (min-width: 1440px) {

}
@media (min-width: 1920px) {

}
</style>